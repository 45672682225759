import router from "./router";
import store from "./store";
import { getToken } from "./utils/storage";

function hasPermission(permissionRoles, path) {
  return permissionRoles.find((role) => path === role.path);
}

const whiteList = [
  "/",
  "/catalog",
  "/galery",
  "/term-and-condition",
  "/cart",
  "/checkout",
  "/404",
]; // no redirect whitelist

router.beforeEach((to, _, next) => {
  if (getToken()) {
    if (!store.getters.g_roles) {
      store
        .dispatch("GetUserInfo")
        .then(() => {
          router.addRoutes(store.getters.g_menu);
          next({ ...to, replace: true });
        })
        .catch(() => {
          // store.dispatch("LogOut").then(() => {
          //   window.location.reload();
          // });
        });
    } else if (hasPermission(store.getters.g_menu, to.path)) {
      next();
    } else {
      // next({ path: "/", replace: true });
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/");
    }
  }
});

router.afterEach(() => {
  // store.commit(
  //   "SET_ACTIVE_MENU",
  //   teacher.find((d) => d.parent === to.meta.parent)
  //     ? teacher.find((d) => d.parent === to.meta.parent).title
  //     : ""
  // ); // Set Initial Active Menu
});
