<template>
  <div class="home mb-12">
    <HomeSlider />
    <HomeCategory />
    <HomeGalery />
    <HomeAboutUs />
    <HomeLocation />
  </div>
</template>

<script>
import HomeSlider from "./homes/HomeSlider.vue";
import HomeCategory from "./homes/HomeCategory.vue";
import HomeGalery from "./homes/HomeGalery.vue";
import HomeAboutUs from "./homes/HomeAboutUs.vue";
import HomeLocation from "./homes/HomeLocation.vue";

export default {
  name: "Home",
  components: {
    HomeSlider,
    HomeCategory,
    HomeGalery,
    HomeAboutUs,
    HomeLocation,
  },
};
</script>
