const getters = {
  // App Modules
  g_profile: (state) => state.app.profile,
  g_snackbar: (state) => state.app.snackbar,
  g_bar: (state) => state.app.bar,
  g_global_loading: (state) => state.app.global_loading,
  g_products: (state) => state.app.products,
  g_categories: (state) => state.app.categories,
  // Permission Modules
  g_route_async: (state) => state.permission.routers,
  addRouters: (state) => state.permission.addRouters,
  // User
  g_roles: (state) => state.user.roles,
  g_token: (state) => state.user.token,
  g_user: (state) => state.user.user,
  g_menu: (state) => state.user.menu,
  g_admin_first_page: (state) => state.user.admin_first_page,
  g_active_menu: (state) => state.user.active_menu,
  // Transaction
  g_cart: (state) => state.transaction.cart,
  g_cart_total: (state) => state.transaction.cart_total,
  g_transaction: (state) => state.transaction.checkout,
};

export default getters;
