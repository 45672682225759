export function setCart(data) {
  localStorage.setItem("cart", data);
}

export function deleteCart() {
  localStorage.removeItem("cart");
}

export function getCart() {
  return localStorage.getItem("cart");
}
