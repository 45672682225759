import request from "../utils/request";

export function userProfile(token) {
  return request({
    method: "post",
    url: "auth/profile",
    headers: { Authorization: `bearer ${token}` },
  });
}

export function userLogout() {
  return request({
    method: "post",
    url: "auth/logout",
  });
}

export function userRegistration(data) {
  return request({
    method: "post",
    url: "auth/registration",
    data,
  });
}

export function userLogin(data) {
  return request({
    method: "post",
    url: "auth/login",
    data,
  });
}
