<template>
  <div class="py-6">
    <v-container>
      <div class="title-border">GALERY</div>
      <v-row class="mt-6">
        <v-col class="d-none d-sm-flex" sm="3">
          <v-card flat class="font-weight-bold">
            See our Collection in the Gallery
          </v-card>
        </v-col>
        <v-col v-if="videoId" cols="12" sm="9">
          <youtube
            style="width: 100%; height: 450px"
            :video-id="videoId"
            :player-vars="playerVars"
          ></youtube>
          <!-- @playing="playing" -->
        </v-col>
        <v-col cols="12">
          <v-row>
            <v-col
              v-for="(item, i) in galeries"
              :key="i"
              cols="6"
              sm="4"
              md="3"
            >
              <v-card outlined style="position: relative; z-index: 1">
                <v-hover v-slot="{ hover }">
                  <v-img :src="item.content" aspect-ratio="1" height="250">
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out primary v-card--reveal white--text justify-center align-center pa-2"
                        style="height: 100%"
                      >
                        <span v-html="item.title"></span>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </v-hover>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getGallery } from "@/api/master";

export default {
  data() {
    return {
      videoId: "",
      playerVars: {
        autoplay: 0,
      },
      galeries: [],
    };
  },
  created() {
    this.getGalleries();
  },
  computed: {
    env() {
      return this.$store.getters.g_profile;
    },
  },
  methods: {
    sliceUrl(url) {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : "";
    },
    async getGalleries() {
      await getGallery({
        content_type: "IMAGE",
        limit: 5,
      }).then((res) => {
        const { code, data } = res.data;
        if (code) {
          this.galeries = data.data;
        }
      });

      this.videoId = this.sliceUrl(this.env.galery_cover);
    },
  },
};
</script>
