<template>
  <div class="py-6">
    <v-container>
      <div class="title-border">ABOUT US</div>
      <v-sheet color="#F2EEEF" class="pa-6 mt-6">
        <v-row>
          <v-col sm="4">
            <v-img :src="profile.logo"></v-img>
          </v-col>
          <v-col cols="12" sm="7">
            <div class="body-2">
              <span v-html="profile.about_us"></span>
            </div>
            <v-btn color="success" depressed class="px-6 mt-4" @click="order"
              >CUSTOM ORDER</v-btn
            >
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
export default {
  computed: {
    profile() {
      return this.$store.getters.g_profile;
    },
  },
  methods: {
    order() {
      if (this.profile.phone_number) {
        const msg = "Hi, i want to custom my order!";
        this.$nextTick(() => {
          window.open(
            `https://wa.me/${this.profile.phone_number}?text=${msg}`,
            "_blank"
          );
        });
      }
    },
  },
};
</script>
