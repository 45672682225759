import router from "@/router";

import { userLogin, userProfile, userLogout } from "@/api/user";
import { getToken, setToken } from "@/utils/storage";

const user = {
  state: {
    roles: "",
    token: getToken(),
    user: {},
    menu: [],
    admin_first_page: "",
    active_menu: "",
  },
  mutations: {
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
      setToken(token);
    },
    SET_USER: (state, value) => {
      state.user = value;
    },
    SET_MENU: (state, value) => {
      state.menu = value;
    },
    SET_ADMIN_FIRST_PAGE: (state, value) => {
      state.admin_first_page = value;
    },
    SET_ACTIVE_MENU: (state, value) => {
      state.active_menu = value;
    },
  },
  actions: {
    // Username login
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        userLogin(userInfo)
          .then((res) => {
            if (res.data.code) {
              const { data } = res.data;
              commit("SET_TOKEN", data.api_token);
            }
            resolve(res);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        userProfile(state.token)
          .then(async (res) => {
            if (res.data.code) {
              const { data } = res.data;

              commit("SET_ROLES", "MEMBER");
              commit("SET_USER", data);
              commit("SET_MENU", [
                {
                  path: "/my-transaction",
                  name: "MyTransaction",
                  meta: { roles: ["MEMBER"] },
                  component: () => import("@/views/MyTransaction.vue"),
                },
              ]);
            } else {
              reject();
            }
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    LogOut({ commit }) {
      return new Promise((resolve) => {
        userLogout()
          .then((res) => {
            if (res.data.code) {
              commit("SET_TOKEN", "");
              commit("SET_ROLES", "");
              commit("SET_USER", {});
              commit("SET_MENU", {});
              router.replace({ path: "/" });
              resolve();
            } else {
              router.replace({ path: "/" });
            }
          })
          .catch(() => {
            setTimeout(() => {
              router.replace({ path: "/" });
              resolve();
            }, 1000);
          });
      });
    },
    LogOutFe({ commit }) {
      return new Promise((resolve) => {
        setTimeout(() => {
          commit("SET_TOKEN", "");
          commit("SET_ROLES", "");
          commit("SET_USER", {});
          commit("SET_MENU", {});
          router.replace({ path: "/login" });
          resolve();
        }, 1000);
      });
    },
  },
};

export default user;
