<template>
  <div class="py-6">
    <v-container>
      <div class="title-border">CATEGORY</div>
      <v-row class="mt-6">
        <v-col class="d-none d-sm-flex" sm="3">
          <div class="font-weight-bold">Categories From our Collection</div>
        </v-col>
        <v-col cols="12" sm="9">
          <v-row>
            <v-col
              cols="12"
              v-if="categorieList().length == 0"
              align-self="start"
              >Category not created yet</v-col
            >
            <v-col
              v-else
              v-for="(cat, i) in categorieList()"
              cols="12"
              sm="4"
              :key="i"
            >
              <v-card
                flat
                style="border: 1px solid #c3c3c3"
                class="text-center"
              >
                <v-img :src="cat.icon" aspect-ratio="1"></v-img>
                <div class="text-uppercase pa-4 font-weight-black">
                  {{ cat.key }}
                </div>
                <div class="px-1 pb-1">
                  <v-btn
                    depressed
                    block
                    color="success"
                    @click="goToCatalog(cat.key)"
                    >VIEW ALL</v-btn
                  >
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      limit: 6,
      categories: [],
    };
  },
  async mounted() {
    const categories = await this.$store.dispatch("getCategories");
    this.categories = categories;
  },
  methods: {
    categorieList() {
      if (this.categories.length == 0) return [];
      return this.categories.slice(0, this.limit);
    },
    goToCatalog(key) {
      this.$router.push(`/catalog?category=${key}`);
    },
  },
};
</script>
