<template>
  <div class="py-6">
    <v-container>
      <div class="title-border">OUR LOCATION</div>
      <v-sheet class="mt-6 rounded-lg">
        <l-map
          style="height: 300px; z-index: 1"
          :zoom="zoom"
          :center="center"
          @click="markerOnClick"
        >
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker :lat-lng="markerLatLng"></l-marker>
        </l-map>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import L from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [-7.2842157, 110.4620251],
      markerLatLng: [-7.2842157, 110.4620251],
    };
  },
  computed: {
    profile() {
      return this.$store.getters.g_profile;
    },
  },
  mounted() {
    if (this.profile.latitude && this.profile.longitude) {
      this.center = [this.profile.latitude, this.profile.longitude];
      this.markerLatLng = [this.profile.latitude, this.profile.longitude];
    }
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  },
  methods: {
    markerOnClick() {
      this.$nextTick(() => {
        window.open(
          "https://maps.google.com/?q=-7.2842157,110.4620251&z=16",
          "_blank"
        );
      });
    },
  },
};
</script>
