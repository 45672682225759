import request from "../utils/request";

export function getCatalog(data) {
  return request({
    method: "post",
    url: "catalog/get",
    data,
  });
}

export function getCategories() {
  return request({
    method: "post",
    url: "categories/get",
  });
}

export function getGallery(data) {
  return request({
    method: "post",
    url: "galery/get",
    data,
  });
}

export function uploadFile(data) {
  return request({
    method: "post",
    header: { "Content-Type": "multipart/form-data" },
    url: "file/upload",
    data,
  });
}

export function getEnv(data) {
  return request({
    method: "post",
    url: "environment/get",
    data,
  });
}

export function getSlidebar() {
  return request({
    method: "post",
    url: "slidebar/get",
  });
}
