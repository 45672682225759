import request from "../utils/request";

export function checkoutTransaction(data) {
  return request({
    method: "post",
    url: "transaction/checkout",
    data,
  });
}

export function getCartData() {
  return request({
    method: "post",
    url: "cart/get",
  });
}

export function setCartData(data) {
  return request({
    method: "post",
    url: "cart/set",
    data,
  });
}

export function getMyTransaction() {
  return request({
    method: "post",
    url: "transaction/history",
  });
}

export function setPayment(data) {
  return request({
    method: "post",
    url: "transaction/pay",
    data,
  });
}

export function getBankAccount() {
  return request({
    method: "post",
    url: "bank-account/get",
  });
}

export function setProof(data) {
  return request({
    method: "post",
    url: "transaction/proof",
    data,
  });
}
