import store from "@/store";
import { getCart, setCart } from "@/utils/db.js";
import {
  checkoutTransaction,
  getCartData,
  setCartData,
} from "@/api/transactions";

const transaction = {
  state: {
    cart_total: 0,
    cart: [],
    checkout: {
      transaction: {
        subtotal: 0,
        tax: null,
        shipping_cost: null,
        total: 0,
        message: "",
      },
      transaction_detail: [],
      address: {
        first_name: "",
        last_name: "",
        company: "",
        address: "",
        address_type: "",
        country: "",
        city: "",
        province: "",
        postal_code: "",
        phone: "",
        remember: false,
      },
    },
  },
  mutations: {
    SET_CART: (state, data) => {
      const newCarts = [];
      if (data && data.length > 0) {
        data.forEach((d) => {
          if (newCarts.filter((n) => n.id === d.id).length === 0) {
            newCarts.push(d);
          }
        });
      }
      const dataCart = JSON.stringify(newCarts);
      setCart(dataCart);
      state.cart = newCarts;
      state.cart_total = newCarts.length;
      if (store.getters.g_token) {
        const query = newCarts.map((r) => {
          return {
            catalog: r.id,
            quantity: r.qty,
          };
        });
        newCarts.length > 0
          ? setCartData({ carts: query })
          : setCartData({ carts: [] });
      }
    },
    SET_CHECKOUT: (state, data) => {
      if (data.type === "transaction") {
        state.checkout.transaction = data.transaction;
        state.checkout.transaction_detail = data.transaction_detail;
      } else state.checkout.address = data.address;
    },
  },
  actions: {
    async actionGetCartData({ commit }) {
      let p = await new Promise((resolve) => {
        let carts = null;

        if (store.getters.g_token) {
          getCartData().then(({ data }) => {
            carts = data.data.map((d) => {
              return {
                ...d,
                id: d.catalog,
                qty: d.quantity,
                stock: d.stok,
              };
            });
            commit("SET_CART", carts);
            resolve(carts);
          });
        } else {
          carts = JSON.parse(getCart());
          commit("SET_CART", carts);
          resolve(carts);
        }
      });
      return p;
    },
    async checkoutHandler({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit("GLOBAL_LOADING");
        checkoutTransaction(state.checkout)
          .then(({ data }) => {
            commit("GLOBAL_LOADING");
            commit("CALL_SYSTEMBAR", {
              msg: "Success create transaction, please check menu my transaction on profile for tracking your orders",
              color: "success",
            });

            resolve(data);
          })
          .catch((err) => {
            commit("CALL_SYSTEMBAR", {
              msg: err.message,
              color: "error",
            });
            reject(err);
          });
      });
    },
  },
};

export default transaction;
