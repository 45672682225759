<template>
  <v-system-bar
    v-if="g_bar.status"
    app
    dark
    :color="g_bar.color"
    :height="30"
    class="text-center"
  >
    <div class="font-weight-bold mx-auto">{{ g_bar.msg }}</div>
  </v-system-bar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SystemBar",
  computed: {
    ...mapGetters(["g_bar"]),
  },
  methods: {
    closeSnackbar() {
      this.$store.commit("CALL_SNACKBAR");
    },
  },
};
</script>
