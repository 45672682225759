<template>
  <div id="app-bar">
    <v-app-bar app flat color="#000" height="75" class="px-2">
      <v-avatar>
        <v-img :src="profile.logo"></v-img>
      </v-avatar>
      <v-spacer></v-spacer>
      <template v-if="!isMobile">
        <template v-for="(menu, idx) in menuList">
          <v-btn
            :key="idx"
            :class="isActiveMenu === menu.path && 'active-btn-appbar'"
            text
            dark
            class="btn-appbar text-none"
            @click="goTo(menu.path)"
          >
            {{ menu.label }}
          </v-btn>
        </template>
        <v-spacer></v-spacer>
        <v-badge
          v-if="totalCart > 0"
          :content="totalCart"
          color="success"
          overlap
          bordered
        >
          <v-btn fab dark small @click="goToCart()">
            <v-icon>mdi-cart</v-icon>
          </v-btn>
        </v-badge>
        <v-btn v-else fab dark small @click="goToCart()">
          <v-icon>mdi-cart</v-icon>
        </v-btn>
      </template>

      <template v-else>
        <v-app-bar-nav-icon
          v-if="isMobile"
          fab
          dark
          small
          @click.stop="menu = !menu"
        />
        <div class="ml-2">
          <v-badge
            v-if="totalCart > 0"
            :content="totalCart"
            color="success"
            overlap
            bordered
          >
            <v-btn fab dark small @click="goToCart()">
              <v-icon>mdi-cart</v-icon>
            </v-btn>
          </v-badge>
          <v-btn v-else fab dark small @click="goToCart()">
            <v-icon>mdi-cart</v-icon>
          </v-btn>
        </div>
      </template>

      <v-menu v-if="user.id" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-2" dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-subheader class="font-weight-black"> Agung Prabowo </v-subheader>
          <v-list-item-group color="primary">
            <v-list-item @click="goTo('/my-transaction')">
              <v-list-item-icon>
                <v-icon color="primary">mdi-view-list</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>My Transaction</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logoutHandler()">
              <v-list-item-icon>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="menu" fixed temporary>
      <v-list dense>
        <v-list-item v-for="(menu, i) in menuList" :key="i" link>
          <v-list-item-content @click="goTo(menu.path)">
            <v-list-item-title>{{ menu.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeMenu: "/",
      menu: false,
      menuList: [
        {
          label: "Home",
          path: "/",
        },
        {
          label: "Catalog",
          path: "/catalog",
        },
        {
          label: "Galery",
          path: "/galery",
        },
        {
          label: "Term & Condition",
          path: "/term-and-condition",
        },
      ],
    };
  },
  watch: {
    isMobile(mob) {
      !mob ? (this.menu = false) : "";
    },
    "$route.path"(newVal) {
      this.activeMenu = newVal;
    },
  },
  computed: {
    isActiveMenu() {
      return this.activeMenu;
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    profile() {
      return this.$store.getters.g_profile;
    },
    totalCart() {
      return this.$store.getters.g_cart_total;
    },
    user() {
      return this.$store.getters.g_user;
    },
  },
  async mounted() {
    setTimeout(() => {
      this.activeMenu = this.$route.path;
    }, 500);
  },
  methods: {
    goTo(path) {
      path !== this.$route.path && this.$router.push(path);
      this.activeMenu = path;
      if (this.isMobile) this.menu = !this.menu;
    },
    goToCart() {
      if (this.$route.path !== "/cart") this.$router.push("/cart");
      this.activeMenu = "/cart";
    },
    logoutHandler() {
      this.$store.dispatch("LogOut");
    },
  },
};
</script>

<style lang="scss">
#app-bar {
  .nav-bar-mob {
    margin-top: 75px;
    text-align: center;
    button.btn-appbar {
      &.active-btn-appbar {
        border-bottom: 3px solid green;
      }
    }
  }
  button.btn-appbar {
    &.active-btn-appbar {
      border-bottom: 3px solid white;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}
</style>
