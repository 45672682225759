<template>
  <v-dialog v-model="isOpen" persistent width="700" overlay-opacity="0.8">
    <v-card color="#DBDBDB" class="d-flex justify-center align-center">
      <v-card-text class="pa-0">
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-img :src="bg" height="100%"></v-img>
          </v-col>
          <v-col cols="12" sm="6" style="relative">
            <div style="position: absolute; right: 10px; top: 10px">
              <v-icon color="primary" @click="close()">mdi-close</v-icon>
            </div>
            <div class="d-flex align-center justify-center">
              <div v-show="islogin" class="black--text text-center py-12">
                <div class="font-weight-black title">Welcome Back!</div>
                <v-form v-model="validLogin" ref="formLogin" lazy-validation>
                  <div class="mt-6">
                    <v-text-field
                      v-model="login.email"
                      :rules="[
                        (v) => !!v || errMsg,
                        (v) => /.+@.+\..+/.test(v) || 'Incorrect email format',
                      ]"
                      dense
                      solo
                      depressed
                      prepend-inner-icon="mdi-account"
                      placeholder="Email"
                      @keyup.enter="loginHandler()"
                    ></v-text-field>
                    <v-text-field
                      v-model="login.password"
                      :append-icon="pwd ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[(v) => !!v || errMsg]"
                      :type="pwd ? 'text' : 'password'"
                      dense
                      solo
                      depressed
                      prepend-inner-icon="mdi-lock"
                      placeholder="Password"
                      @click:append="pwd = !pwd"
                      @keyup.enter="loginHandler()"
                    ></v-text-field>
                    <v-btn
                      color="primary"
                      depressed
                      block
                      @click="loginHandler()"
                      >Login</v-btn
                    >

                    <div class="mt-6">
                      Don't have an account?
                      <a class="primary--text" @click="changeForm('login')"
                        >Register here</a
                      >
                    </div>
                  </div>
                </v-form>
              </div>
              <div v-show="!islogin" class="black--text text-center py-6">
                <div class="font-weight-black title">Register Here</div>
                <v-form
                  v-model="validRegistration"
                  ref="formRegistration"
                  lazy-validation
                >
                  <div class="mt-6">
                    <v-text-field
                      v-model="registration.name"
                      :rules="[(v) => !!v || errMsg]"
                      dense
                      solo
                      depressed
                      prepend-inner-icon="mdi-account"
                      placeholder="Name"
                      @keyup.enter="regisHandler()"
                    ></v-text-field>
                    <v-text-field
                      v-model="registration.email"
                      :rules="[
                        (v) => !!v || errMsg,
                        (v) => /.+@.+\..+/.test(v) || 'Incorrect email format',
                      ]"
                      dense
                      solo
                      depressed
                      prepend-inner-icon="mdi-email"
                      placeholder="Email"
                      @keyup.enter="regisHandler()"
                    ></v-text-field>
                    <v-text-field
                      v-model="registration.password"
                      :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[(v) => !!v || errMsg]"
                      :type="showPwd ? 'text' : 'password'"
                      dense
                      solo
                      depressed
                      prepend-inner-icon="mdi-lock"
                      placeholder="Password"
                      @click:append="showPwd = !showPwd"
                      @keyup.enter="regisHandler()"
                    ></v-text-field>
                    <v-text-field
                      v-model="registration.confirm_password"
                      :append-icon="showPwdConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[
                        (v) => !!v || errMsg,
                        (v) =>
                          v == registration.password || 'Password didn`t match',
                      ]"
                      :type="showPwdConfirm ? 'text' : 'password'"
                      dense
                      solo
                      depressed
                      prepend-inner-icon="mdi-lock"
                      placeholder="Confirm Password"
                      @click:append="showPwdConfirm = !showPwdConfirm"
                      @keyup.enter="regisHandler()"
                    ></v-text-field>
                    <v-btn
                      color="primary"
                      depressed
                      block
                      @click="regisHandler()"
                      >Register</v-btn
                    >

                    <div class="mt-6">
                      Have an Account?
                      <a class="primary--text" @click="changeForm('regis')"
                        >Login</a
                      >
                    </div>
                  </div>
                </v-form>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { userRegistration } from "@/api/user";

export default {
  props: {
    isOpen: Boolean,
    close: Function,
  },
  data() {
    return {
      validLogin: false,
      validRegistration: false,
      islogin: true,
      errMsg: "Please fill out this field",
      bg: "",
      pwd: false,
      showPwd: false,
      showPwdConfirm: false,
      login: {
        email: "",
        password: "",
      },
      registration: {
        email: "",
        password: "",
        confirm_password: "",
        name: "",
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.bg = this.$store.getters.g_profile.home_cover;
    }, 200);
  },
  methods: {
    changeForm(param) {
      if (param === "login") {
        this.islogin = false;
        this.registration = {
          email: "",
          password: "",
          confirm_password: "",
          name: "",
        };
        this.$refs.formRegistration &&
          this.$refs.formRegistration.resetValidation();
      } else {
        this.islogin = true;
        this.login = {
          email: "",
          password: "",
        };
        this.$refs.formLogin && this.$refs.formLogin.resetValidation();
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    loginApi() {
      this.$store.commit("GLOBAL_LOADING");
      this.$store
        .dispatch("Login", this.login)
        .then((res) => {
          const { code, message } = res.data;
          if (code) {
            this.$store.commit("CALL_SNACKBAR", {
              msg: message,
              color: "success",
            });
            if (this.$route.path === "/cart") {
              this.$router.push("/checkout");
            } else {
              this.$router.push("/my-transaction");
            }
          } else {
            this.$store.commit("CALL_SNACKBAR", {
              msg: message,
              color: "error",
            });
          }
          this.$store.commit("GLOBAL_LOADING");
        })
        .catch(() => this.$store.commit("GLOBAL_LOADING"));
    },
    loginHandler(param) {
      if (param === "registration") {
        this.loginApi();
      } else if (this.$refs.formLogin.validate()) {
        this.loginApi();
      }
    },
    regisHandler() {
      if (this.$refs.formRegistration.validate()) {
        this.$store.commit("GLOBAL_LOADING");
        userRegistration(this.registration)
          .then((res) => {
            const { code, message } = res.data;
            if (code) {
              this.$store.commit("CALL_SNACKBAR", {
                msg: "Registration is success",
                color: "success",
              });
              this.login = {
                email: this.registration.email,
                password: this.registration.password,
              };
              this.loginHandler("registration");
            } else {
              this.$store.commit("CALL_SNACKBAR", {
                msg: message,
                color: "error",
              });
            }
            this.$store.commit("GLOBAL_LOADING");
          })
          .catch(() => this.$store.commit("GLOBAL_LOADING"));
      }
    },
  },
};
</script>
