<template>
  <div id="footer">
    <v-row class="pa-12 white--text mx-0">
      <v-col cols="12" sm="3" class="d-flex align-center justify-center">
        <v-avatar size="100px">
          <v-img :src="profile.logo"></v-img>
        </v-avatar>
      </v-col>
      <v-col cols="12" sm="3" :class="isMobile() && 'text-center'">
        <div class="font-weight-bold body-2 mb-4">CONTACT US</div>
        <div class="d-flex align-center mb-2">
          <v-icon color="success" class="mr-2">mdi-phone</v-icon>
          <div class="mb-1 caption">{{ profile.phone_number }}</div>
        </div>
        <div class="d-flex align-center mb-2">
          <v-icon color="success" class="mr-2">mdi-email</v-icon>
          <div class="mb-1 caption">{{ profile.email }}</div>
        </div>
        <div class="d-flex align-center">
          <v-icon color="success" class="mr-2">mdi-map-marker</v-icon>
          <div class="mb-1 caption">{{ profile.address }}</div>
        </div>
      </v-col>
      <v-col cols="6" sm="3">
        <div class="font-weight-bold body-2 mb-4">MAIN MENU</div>
        <div>
          <div class="mb-1 caption" style="cursor: pointer" @click="goTo('/')">
            Home
          </div>
          <div
            class="mb-1 caption"
            style="cursor: pointer"
            @click="goTo('/catalog')"
          >
            Catalog
          </div>
          <div
            class="mb-1 caption"
            style="cursor: pointer"
            @click="goTo('/galery')"
          >
            Gallery
          </div>
          <div
            class="mb-1 caption"
            style="cursor: pointer"
            @click="goTo('/term-and-condition')"
          >
            Term & Condition
          </div>
        </div>
      </v-col>
      <v-col cols="6" sm="3">
        <div class="font-weight-bold body-2 mb-4">FOLLOW US</div>
        <div class="d-flex">
          <v-btn
            v-if="profile.instagram"
            icon
            small
            dark
            @click="openLink(profile.instagram)"
            ><v-icon>mdi-instagram</v-icon></v-btn
          >
          <v-btn
            v-if="profile.youtube"
            icon
            small
            dark
            @click="openLink(profile.youtube)"
            ><v-icon>mdi-youtube</v-icon></v-btn
          >
          <v-btn
            v-if="profile.facebook"
            icon
            small
            dark
            @click="openLink(profile.facebook)"
            ><v-icon>mdi-facebook</v-icon></v-btn
          >
        </div>
      </v-col>
    </v-row>
    <div
      style="background: #252525"
      class="caption pa-2 text-center white--text"
    >
      <small>&copy; Copyright {{ getYear() }}, Creetech Company</small>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shares: [
        {
          icon: "mdi-instagram",
          link: "https://www.instagram.com/epp.plants",
        },
        {
          icon: "mdi-youtube",
          link: "https://www.instagram.com/epp.plants",
        },
      ],
    };
  },
  computed: {
    profile() {
      return this.$store.getters.g_profile;
    },
  },
  methods: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    goTo(path) {
      this.$router.push(path);
    },
    getYear() {
      return new Date().getFullYear();
    },
    openLink(link) {
      this.$nextTick(() => {
        window.open(link, "_blank");
      });
    },
  },
};
</script>

<style lang="scss">
#footer {
  background: #000000;
}
</style>
