import { getCatalog, getCategories, getEnv } from "@/api/master";

const app = {
  state: {
    profile: {
      logo: require("@/assets/epp-plant-logo.jpeg"),
      name: "Epp Plant",
      instagram: "",
      youtube: "",
      facebook: "",
      about_us: "",
      phone_number: "",
      latitude: "",
      longitude: "",
      email: "",
      address: "",
      term_and_condition: "",
      home_cover: "",
      galery_cover: "",
      bank_fee: "",
    },
    snackbar: {
      status: false,
      timeout: 5000,
      color: "blue-grey darken-2",
      msg: "",
      mode: "",
      positionx: "",
      positiony: "",
    },
    bar: {
      status: false,
      color: "success",
      msg: "",
    },
    shop: [],
    global_loading: false,
    products: [],
    categories: [],
  },
  mutations: {
    CALL_SNACKBAR: (state, data) => {
      state.snackbar.status = false;

      if (data) {
        setTimeout(() => {
          state.snackbar.msg = data.msg ? data.msg : "";
          state.snackbar.color = data.color ? data.color : "";
          state.snackbar.positionx = data.x ? data.x : "center";
          state.snackbar.positiony = data.y ? data.y : "bottom";
          state.snackbar.status = true;
          state.snackbar.timeout = data.timeout ? data.timeout : 5000;
        }, 200);
      }
    },
    CALL_SYSTEMBAR: (state, data) => {
      state.bar.msg = data.msg ? data.msg : "";
      state.bar.color = data.color ? data.color : "success";
      state.bar.status = true;

      if (data) {
        setTimeout(() => {
          state.bar.msg = "";
          state.bar.color = "sucess";
          state.bar.status = false;
        }, 5000);
      }
    },
    GLOBAL_LOADING: (state) => {
      state.global_loading = !state.global_loading;
    },
    SET_PRODUCTS: (state, data) => {
      state.products = data;
    },
    SET_CATEGORIES: (state, data) => {
      state.categories = data;
    },
    SET_PROFILE: (state, data) => {
      state.profile = data;
    },
  },
  actions: {
    async getEnvHandler({ commit }) {
      return new Promise((resolve) => {
        commit("GLOBAL_LOADING");
        getEnv()
          .then((res) => {
            commit("SET_PROFILE", res.data.data);
            commit("GLOBAL_LOADING");
            resolve(res.data.data);
          })
          .catch(() => commit("GLOBAL_LOADING"));
      });
    },
    async getCatalogs({ commit }, data) {
      return new Promise((resolve) => {
        commit("GLOBAL_LOADING");
        const newData = {
          ...data,
          category: data.category === "ALL" ? "" : data.category,
        };
        getCatalog(newData)
          .then((res) => {
            commit("SET_PRODUCTS", res.data.data.data);
            commit("GLOBAL_LOADING");
            resolve(res.data.data);
          })
          .catch(() => commit("GLOBAL_LOADING"));
      });
    },
    async getCategories({ commit }) {
      return new Promise((resolve) => {
        getCategories().then((res) => {
          commit("SET_CATEGORIES", res.data.data.data);
          resolve(res.data.data.data);
        });
      });
    },
  },
};

export default app;
