<template>
  <v-app id="app">
    <v-overlay absolute opacity="0.4" z-index="1000" :value="globalLoading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <SystemBar />
    <Snackbar />

    <AppBar />
    <v-main>
      <router-view />
    </v-main>
    <Footer />

    <!-- <Currency /> -->
    <div id="ctr-wa-float">
      <v-btn fab small color="#075e54" @click="order">
        <v-icon color="white">mdi-whatsapp</v-icon>
      </v-btn>
    </div>
  </v-app>
</template>

<script>
// import Currency from "./components/Currency.vue";
import SystemBar from "./components/SystemBar.vue";
import AppBar from "./components/AppBar.vue";
import Footer from "./components/Footer.vue";
import Snackbar from "./components/Snackbar.vue";

export default {
  name: "App",
  components: {
    AppBar,
    Footer,
    Snackbar,
    SystemBar,
  },
  computed: {
    globalLoading() {
      return this.$store.getters.g_global_loading;
    },
    profile() {
      return this.$store.getters.g_profile;
    },
  },
  created() {
    this.$store.dispatch("getEnvHandler");
    this.$store.dispatch("actionGetCartData");
  },
  methods: {
    order() {
      if (this.profile.phone_number) {
        const msg = "Hi, i want to ask about your product!";
        this.$nextTick(() => {
          window.open(
            `https://wa.me/${this.profile.phone_number}?text=${msg}`,
            "_blank"
          );
        });
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  position: relative;
  #ctr-wa-float {
    z-index: 10;
    position: fixed;
    bottom: 20px;
    right: 20px;
  }
}
</style>
