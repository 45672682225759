<template>
  <div id="home-slider">
    <v-carousel hide-delimiter-background hide-delimiters show-arrows>
      <v-carousel-item v-for="(slide, i) in slides" :key="i" class="home-item">
        <!-- <div id="home-share" class="d-flex flex-column">
          <v-btn
            v-if="profile.instagram"
            icon
            small
            dark
            @click="openLink(profile.instagram)"
            ><v-icon>mdi-instagram</v-icon></v-btn
          >
          <v-btn
            v-if="profile.youtube"
            icon
            small
            dark
            @click="openLink(profile.youtube)"
            ><v-icon>mdi-youtube</v-icon></v-btn
          >
          <v-btn
            v-if="profile.facebook"
            icon
            small
            dark
            @click="openLink(profile.facebook)"
            ><v-icon>mdi-facebook</v-icon></v-btn
          >
        </div> -->
        <!-- id="home-btn-shop" -->
        <div v-if="slide.caption" id="home-share">
          <div
            class="body-1 font-weight-bold white--text mb-4 px-12 text-center"
          >
            {{ slide.caption }}
          </div>
          <div class="d-flex flex-column flex-sm-row" style="gap: 10px">
            <v-btn color="success" class="px-12" to="/catalog"
              >SHOP <v-icon class="ml-2">mdi-arrow-right</v-icon></v-btn
            >
            <v-btn v-if="!user.id" @click="popupLogin = true"
              >LOGIN / REGISTER</v-btn
            >
          </div>
        </div>
        <div
          v-if="slide.caption && slide.caption !== ' '"
          class="backdrop"
        ></div>
        <v-img :src="slide.image" :lazy-src="slide.image"></v-img>
      </v-carousel-item>
    </v-carousel>

    <PopupLogin :isOpen="popupLogin" :close="() => (popupLogin = false)" />
  </div>
</template>

<script>
import { getSlidebar } from "@/api/master";
import PopupLogin from "@/components/PopupLogin.vue";
import store from "@/store";

export default {
  components: {
    PopupLogin,
  },
  data() {
    return {
      popupLogin: false,
      slides: [],
      shares: [
        {
          icon: "mdi-instagram",
          link: "https://www.instagram.com/epp.plants",
        },
        {
          icon: "mdi-youtube",
          link: "https://www.instagram.com/epp.plants",
        },
      ],
    };
  },
  computed: {
    user() {
      return store.getters.g_user;
    },
    profile() {
      return store.getters.g_profile;
    },
  },
  mounted() {
    getSlidebar().then(({ data }) => {
      this.slides.push(...data.data);
    });
  },
  methods: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    openLink(link) {
      if (link) {
        this.$nextTick(() => {
          window.open(link, "_blank");
        });
      }
    },
  },
};
</script>

<style lang="scss">
#home-slider {
  min-height: calc(100vh - 75px) !important;
  position: relative;
  .v-item-group {
    height: calc(100vh - 75px) !important;
  }
  .home-item {
    .backdrop {
      z-index: 1;
      position: absolute;
      background-image: linear-gradient(
        to top,
        rgba(black, 0.8),
        rgba(black, 0.3)
      );
      height: 100%;
      width: 100%;
      bottom: 0px;
    }
    #home-share {
      z-index: 3;
      position: absolute;
      bottom: 50px;
      width: 100%;
      gap: 20px;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    #home-btn-shop {
      z-index: 2;
      position: absolute;
      padding-top: 30%;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .v-image {
      height: calc(100vh - 75px) !important;
    }
  }
}
</style>
