import axios from "axios";
import { getToken } from "./storage";

import store from "@/store";

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api of base_url
  timeout: 1000000, // timeout default
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    const conf = config;
    if (getToken()) {
      conf.headers.Authorization = `Bearer ${getToken()}`;
    }
    return conf;
  },
  (error) => {
    Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => response,
  // eslint-disable-next-line consistent-return
  (error) => {
    const e = error;
    console.log("error", e);
    if (e && e.response) {
      const r = e.response;
      if (r.status === 401) {
        store.dispatch("LogOutFe");
        store.commit("CALL_SNACKBAR", {
          msg: r.data.message,
          color: "error",
        });
      } else {
        store.commit("CALL_SNACKBAR", {
          msg: r.data.message,
          color: "error",
        });
      }
      return Promise.reject(e);
    }
    store.commit("CALL_SNACKBAR", {
      msg: e,
      color: "error",
    });
  }
);

export default service;
