<template>
  <v-snackbar
    :value="g_snackbar.status"
    :color="g_snackbar.color"
    :timeout="g_snackbar.timeout"
  >
    <div class="d-flex align-center">
      <span v-html="g_snackbar.msg" />
      <v-spacer />
      <v-btn icon dark small @click="closeSnackbar">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "snackbar",
  computed: {
    ...mapGetters(["g_snackbar"]),
  },
  methods: {
    closeSnackbar() {
      this.$store.commit("CALL_SNACKBAR");
    },
  },
};
</script>
