import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#04BF68",
        secondary: "#C3C3C3",
        accent: "#333333",
        error: "#FF0000",
        info: "#2196F3",
        success: "#04BF68",
        warning: "#FFA000",
      },
      dark: {
        primary: "#27ace2",
        secondary: "#3E4295",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFA000",
      },
    },
  },
});
